import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondaryLight'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonSecondaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    margin: 0 auto;
    color: ${({ theme }) => theme.color.background};
    font-size: ${({ theme }) => theme.font.size.huge};
    margin-bottom: 3rem;
    @media (min-width: 576px) {
      width: 75%;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: ${({ theme }) => theme.color.background};
  }
`

const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.background};

  & .form-duuf-group {
    width: 100%;
  }

  & .form-duuf-input,
  & .form-duuf-textarea {
    border-radius: unset;
    padding: 0.8rem 1rem;
  }
`

const Content = styled(ParseContent)`
  & table {
    & tr {
      padding-bottom: 1.6rem;
      & > td {
        padding-right: 1.4rem;
      }
    }
  }

  & a {
    font-weight: ${({ theme }) => theme.font.weight.light} !important;
    &:hover {
      text-decoration: underline;
    }
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <section className="mb-5 pb-5">
    <div className="container">
      <StyledFormDuuf id={1} className="mt-5" generate={false}>
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="row justify-content-end">
              <div className="col-xl-5 mb-5 mb-xl-0">
                <Content content={fields.description} />
              </div>
              <div className="col-xl-7">
                <FormWrapper className="d-flex flex-column align-items-center pt-sm-5 px-sm-5 pt-4 px-4">
                  <FormDuufGroup />
                  <FormDuufSubmit />
                </FormWrapper>
              </div>
            </div>
          </div>
        </div>
      </StyledFormDuuf>
    </div>
  </section>
)

export default FormContact
